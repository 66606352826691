import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Slide, { SlideProps } from '@mui/material/Slide';
import MuiAlert, { AlertProps, AlertColor } from '@mui/material/Alert';
import isotipoWhite from '../../assets/isotipo_white.svg';
import isotipoDark from '../../assets/isotipo_dark_secondary.svg';

type TransitionProps = Omit<SlideProps, 'direction'>;

function TransitionUp(props: TransitionProps) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Slide {...props} direction="down" />;
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(
  (
    props,
    ref,
    // eslint-disable-next-line react/jsx-props-no-spreading
  ) => <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />,
);

type BancameAlertProps = {
  button?: React.ReactElement;
  successMessage: string | React.ReactNode;
  errorMessage: string;
  action?: () => void;
  error?: boolean;
  openAlert?: boolean;
}

const defaultProps = {
  button: null,
  action: () => {},
  error: false,
  openAlert: false,
};

export default function BancameAlert(props: BancameAlertProps) {
  const {
    button,
    successMessage,
    errorMessage,
    action,
    error,
    openAlert = false,
  } = props;
  const [open, setOpen] = React.useState(false);
  const [severity, setSeverity] = React.useState<AlertColor>(
    error ? 'error' : 'success',
  );
  const [message, setMessage] = React.useState(
    error ? errorMessage : successMessage,
  );
  const handleClick = () => {
    try {
      if (typeof action === 'function') {
        action();
      }
      setSeverity('success');
      setMessage(successMessage);
      setOpen(true);
    } catch (e) {
      setSeverity('error');
      setMessage(errorMessage);
      setOpen(true);
    }
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  React.useEffect(() => {
    setOpen(openAlert);
    setTimeout(() => setOpen(false), 5000);
  }, [openAlert]);
  React.useEffect(() => {
    if (error) {
      setSeverity('error');
      return setMessage(errorMessage);
    }
    setSeverity('success');
    return setMessage(successMessage);
  }, [errorMessage, successMessage, error]);

  return (
    <>
      {button && React.cloneElement(button, { onClick: handleClick })}
      <Snackbar
        TransitionComponent={TransitionUp}
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          icon={(
            <img
              width={20}
              src={severity === 'success' ? isotipoDark : isotipoWhite}
              alt="Isotipo banca.me"
            />
)}
          onClose={handleClose}
          severity={severity}
          variant="standard"
          sx={{ width: '100%', alignContent: 'center', alignItems: 'center' }}
        >
          {message}
        </Alert>
      </Snackbar>
    </>
  );
}

BancameAlert.defaultProps = defaultProps;
