import React from 'react';
import { Button } from '@mui/material';
import analytics from '../../../utils/analytics';
import AlertDialog from '../../AlertDialog';
import MyIframeComponent from './EtpayIframe';

interface EtPayPaymentProps {
  widgetToken: string | undefined;
  onError: ({ message, resetWidgetToken }: { message: string, resetWidgetToken?: boolean }) => void;
  onSuccess: () => Promise<void>;
  onExit: () => void;
  label?: React.ReactNode;
}

export default function EtPayPayment(props: EtPayPaymentProps) {
  const {
    widgetToken, onError, onSuccess, onExit, label,
  } = props;

  const [open, setOpen] = React.useState(false);
  const [closeTrigger, setCloseTrigger] = React.useState(false);

  const handleOpenWidget = async () => {
    if (widgetToken) {
      analytics.track('Click Boton', {
        text: 'ASOCIAR PAC',
        initiator: 'usuario',
        paymentMethod: 'etpay',
      });
      setOpen(true);
    }
  };

  const closeWidget = () => {
    setCloseTrigger(true);
  };

  const handleCloseWidget = () => {
    closeWidget();
    onExit();
  };

  const handleSuccess = async () => {
    analytics.track('Click Boton', {
      initiator: 'usuario',
      text: 'Terminar',
      paymentMethod: 'etpay',
    });
    closeWidget();
    onSuccess();
  };

  const handleError = (message: string) => {
    analytics.track('Etpay', {
      initiator: 'usuario',
      paymentMethod: 'etpay',
      error: message,
    });
    closeWidget();
    onError({ message, resetWidgetToken: true });
  };

  React.useEffect(() => {
    if (closeTrigger) {
      setCloseTrigger(false);
    }
    if (open) {
      setOpen(false);
    }
  }, [closeTrigger, open]);

  return (
    <AlertDialog
      isTriggered={open}
      triggerClose={closeTrigger}
      dialogContentStyle={{ padding: 0 }}
      dialogContent={widgetToken ? (
        <MyIframeComponent
          widgetToken={widgetToken}
          onSuccess={handleSuccess}
          onError={handleError}
          onClose={handleCloseWidget}
        />
      ) : (
        <div>
          No se encontró un token de sesión válido.
        </div>
      )}
      hideCloseButton
      hideSubmitButton
      hideLogo
      hideActions
      hideTitle
      maxWidth="xl"
      button={(
        <Button
          onClick={handleOpenWidget}
          variant="contained"
          color="primary"
          style={{ marginTop: '20px' }}
          disabled={!widgetToken}
        >
          {label}
        </Button>
      )}
    />
  );
}

EtPayPayment.defaultProps = {
  label: 'Asociar PAC',
};
