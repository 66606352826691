import React from 'react';
import { getFintoc } from '@fintoc/fintoc-js';
import { Button } from '@mui/material';
import analytics from '../../../utils/analytics';
import getFintocGTMEventLabel from './helpers';

interface FintocPaymentProps {
  idNumber: string;
  widgetToken: string | undefined;
  onError: ({ message, resetWidgetToken }: { message: string, resetWidgetToken?: boolean }) => void;
  onSuccess: () => Promise<void>;
  onExit: () => void;
  label?: React.ReactNode;
}

export default function FintocPayment(props: FintocPaymentProps) {
  const {
    idNumber,
    widgetToken,
    onError,
    onSuccess,
    onExit,
    label,
  } = props;

  let secondFactor: string | null = null;
  let Fintoc: any | null = null;
  const [widget, setWidget] = React.useState<any | null>(null);

  const openWidget = async () => {
    if (!widgetToken) return;

    analytics.track('Click Boton', {
      text: 'ASOCIAR PAC',
      initiator: 'usuario',
      paymentMethod: 'fintoc',
    });

    const options = {
      holderType: 'individual',
      product: 'subscriptions',
      publicKey: process.env.REACT_APP_FINTOC_PUBLIC_KEY,
      onExit: async () => {
        onExit();
      },
      onSuccess: async (event: {
        subscription: { account: { institution: { name: string } } };
      }) => {
        analytics.track('Click Boton', {
          initiator: 'usuario',
          text: 'Terminar',
          bank: event.subscription.account.institution.name,
          paymentMethod: 'fintoc',
        });
        onSuccess();
      },
      username: ['dev', 'beta', 'localhost'].some((env) => process.env.REACT_APP_API_URL?.includes(env))
        ? undefined
        : idNumber,
      onEvent: async (event: string) => {
        if (event === 'on_error') {
          onError({
            message: getFintocGTMEventLabel(event)
              || 'Ha ocurrido un error, por favor contáctanos',
          });
          analytics.page('PU - PAGOS', getFintocGTMEventLabel(event), {
            paymentMethod: 'fintoc',
          });
          return null;
        }
        if (event === 'closed') {
          analytics.track('Cierre Dialog', {
            initiator: 'usuario',
            paymentMethod: 'fintoc',
          });
          return null;
        }
        if (event.includes('authentication_required')) {
          const sf = event.split('_')[0];
          secondFactor = sf;
        }
        if (event === 'subscription_created') {
          analytics.track('PAC Suscrito', {
            initiator: 'usuario',
            authenticationMethod: secondFactor,
            paymentMethod: 'fintoc',
          });
        }
        analytics.page('PU - PAGOS', getFintocGTMEventLabel(event), {
          paymentMethod: 'fintoc',
        });
        return null;
      },
    };
    if (widget) {
      widget.open();
      return;
    }
    Fintoc = await getFintoc();
    const Widget = await Fintoc?.create({ ...options, widgetToken });
    setWidget(Widget);
    Widget.open();
  };

  React.useEffect(() => {
    if (widgetToken && widget) {
      setWidget(null);
    }
  }, [widgetToken]);

  return (
    <Button
      onClick={openWidget}
      variant="contained"
      color="primary"
      style={{ marginTop: '20px' }}
      disabled={!widgetToken}
    >
      {label}
    </Button>
  );
}

FintocPayment.defaultProps = {
  label: 'Asociar PAC',
};
